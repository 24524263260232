import { motion } from "framer-motion";

function AboutUsBanner({ translateY }) {
  return (
    <div className="w-full h-[50rem] max-xs:h-[15rem] my-8 overflow-hidden flex justify-center items-center">
      <motion.img
        className="w-full h-auto"
        src={require("../../images/aboutus-banner.webp")}
        alt="About Us Banner"
        style={{ translateY: translateY }}
      />
    </div>
  );
}
export default AboutUsBanner;
