import { useEffect, useState } from "react";
import MobileFooter from "../mobile-footer";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";

function MobileHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  const sidebarMenu = [
    // { label: "Services", link: "/" },
    { label: "Projects", link: "/projects" },
    { label: "Contact Us", link: "/contact-us" },
  ];
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (menu) => {
    navigate(menu.link);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 50;
      setIsScrolled(scrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleServiceClick = () => {
    setIsOpen(!isOpen);
    setTimeout(() => {
      if (location.pathname == "/") {
        document
          .getElementById("services")
          .scrollIntoView({ behavior: "smooth" });
      } else {
        navigate("/");
        setTimeout(() => {
          document
            .getElementById("services")
            .scrollIntoView({ behavior: "smooth" });
        }, 600);
      }
    }, 1000);
  };

  return (
    <>
      <div
        className={`w-full h-auto fixed top-0 left-0 z-50 ${
          isScrolled ? "bg-black bg-opacity-25 backdrop-blur-lg" : ""
        }`}
      >
        <div className="w-full h-auto">
          <div className="w-full h-auto px-[2.2rem] py-[1.8rem] flex justify-between items-center border-b border-[#494949]">
            <div className="w-[5.5rem] h-auto">
              <Link className="w-full h-auto" to={"/"}>
                <img
                  src={require("../../images/7dDesignLogo.png")}
                  alt="7d Design"
                />
              </Link>
            </div>
            <button
              type="button"
              className="w-auto h-auto"
              onClick={toggleMenu}
            >
              {isOpen ? (
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.39258 2.3934L23.6058 23.6066"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                  <path
                    d="M2.39258 23.6066L23.6058 2.39342"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                </svg>
              ) : (
                <svg
                  width="34"
                  height="14"
                  viewBox="0 0 34 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2H32"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                  <path
                    d="M2 12H32"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      <div
        className={`w-full h-screen fixed top-0 left-0 z-[49] transition-transform duration-300 ${
          isOpen ? "" : "translate-x-full"
        }`}
      >
        <div className="w-full h-full bg-7d-orange pt-[10vh]">
          <div className="w-full h-auto pt-16 pr-8">
            <ul className="w-full h-auto">
              <li
                className="w-full h-auto pb-2 cursor-pointer"
                onClick={handleServiceClick}
              >
                <div className="w-full h-auto flex justify-end items-end gap-8">
                  <p className="text-[5.2rem] font-anton font-normal leading-[6rem] uppercase text-7d-white">
                    Services
                  </p>
                  <div className="w-[3.3rem] h-auto">
                    <img
                      src={require("../../images/sidebar-links-arrow.png")}
                      alt="Arrow Icon"
                    />
                  </div>
                </div>
              </li>
              {sidebarMenu.map((menu, i) => (
                <li
                  className="w-full h-auto pb-2 cursor-pointer"
                  onClick={() => handleClick(menu)}
                >
                  <div className="w-full h-auto flex justify-end items-end gap-8">
                    <p className="text-[5.2rem] font-anton font-normal leading-[6rem] uppercase text-7d-white">
                      {menu.label}
                    </p>
                    <div className="w-[3.3rem] h-auto">
                      <img
                        src={require("../../images/sidebar-links-arrow.png")}
                        alt="Arrow Icon"
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full h-auto mt-[20%]">
            <MobileFooter />
          </div>
        </div>
      </div>
    </>
  );
}
export default MobileHeader;
