import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence, usePresence } from "framer-motion";
import { gsap } from "gsap";

function VisualBranding() {
  const navigate = useNavigate();
  const location = useLocation();
  const sideContainer = useRef();

  const [isExiting, setIsExiting] = useState(location.state);
  const [isPresent, safeToRemove] = usePresence();
  const ref = useRef(null);

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    const handleClickOutside = (event) => {
      if (
        sideContainer.current &&
        !sideContainer.current.contains(event.target)
      ) {
        if (!isPresent) {
          setIsExiting(true);

          console.log("Rare scenerio...!");
        } else {
          setIsExiting(false);
          gsap.to(ref.current, {
            opacity: 1,
            duration: 1,
            ease: [0.43, 0.13, 0.23, 0.96],
            onComplete: () => {
              navigate("/projects");
            },
          });
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      y: "0vw",
    },
    in: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <AnimatePresence>
      {isExiting ? (
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={{ duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] }}
          ref={ref}
          className="w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-[10]"
        >
          <motion.div
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: [0.43, 0.13, 0.23, 0.96],
            }}
            className="w-[74%] max-3xl:w-[73.5%] max-xs:w-full h-full ml-auto rounded-tl-[1rem] bg-7d-gray-20"
            ref={sideContainer}
          >
            <div className="w-full h-full pr-0 max-xs:p-8 max-xs:pr-0">
              <div className="w-full h-full max-xs:pr-8 max-xs:h-[85vh] max-xs:mt-[10vh] overflow-auto">
                <Outlet />
              </div>
            </div>
          </motion.div>
        </motion.div>
      ) : (
        ""
      )}
    </AnimatePresence>
  );
}
export default VisualBranding;
