import DesktopExplore from "./desktop-explore";

function Explore() {
  return (
    <>
      <div className="block max-xs:hidden">
        <DesktopExplore />
      </div>
    </>
  );
}

export default Explore;
