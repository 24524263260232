const InputField = ({ type, label,setvalue,name,validate }) => {
  return (
    <div className="w-full h-auto mb-5">
      <input
        type={type}
        className="w-full h-auto px-6 py-5 rounded-[1rem] bg-7d-gray-19 text-[2.4rem] max-xs:text-[1.9rem] font-blinker font-light"
        placeholder={label}
        name={name}
        required={validate}
        onChange={(e)=>{

          setvalue((prev)=>{
            return{
              ...prev,
              [name]:e.target.value
            }
          })

        }}
      />
    </div>
  );
};
const TextAreaField = ({ type, label,name,setvalue }) => {
  return (
    <div className="w-full h-auto mb-5">
      <textarea
        rows={8}
        cols={5}
        className="w-full h-auto px-6 py-5 rounded-[1rem] bg-7d-gray-19 text-[2.4rem] max-xs:text-[1.9rem] font-blinker font-light"
        placeholder={label}
        name={name}
        onChange={(e)=>{

          setvalue((prev)=>{
            return{
              ...prev,
              [name]:e.target.value
            }
          })

        }}
      ></textarea>
    </div>
  );
};
export { InputField, TextAreaField };
