import ContactForm from "../contact-form";
import ContactGradientText from "../contact-gradient-text";
import { motion } from "framer-motion";

function Contact() {
  const cardVariants = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 1,
      },
    },
  };
  return (
    <div className="w-full h-auto mb-14">
      <div className="w-full h-auto pb-20 max-xs:pb-10 border-b border-7d-gray-70">
        <div className="w-full h-auto relative">
          <ContactGradientText />
        </div>
      </div>
      <div className="w-full h-full my-24 max-xs:my-12">
        <div className="w-full h-full flex justify-between items-stretch max-xs:flex-col">
          <div className="w-1/2 h-auto max-xs:w-full max-xs:mb-8">
            <motion.div
              className="w-full h-full flex flex-col justify-between"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <div className="w-full h-auto">
                <div className="w-full h-auto pb-12">
                  <p className="primary-heading">Get in touch</p>
                </div>
                <div className="w-[90%] h-auto">
                  <div className="w-full h-full overflow-hidden">
                    <motion.div className="w-full" variants={cardVariants}>
                      <p className="secondary-heading">Let's Talk.</p>
                    </motion.div>
                  </div>
                  <div className="w-full h-full overflow-hidden">
                    <motion.div className="w-full" variants={cardVariants}>
                      <p className="secondary-heading">
                        Please fill in the details and we will be in touch
                        shortly or send us email or Call us
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="w-full h-auto block max-xs:hidden">
                <div className="w-full h-auto">
                  {/* <p className="primary-heading">digital@7ddesign.in</p> */}
                  <p className="primary-heading">+91 9496128372</p>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="w-1/2 h-full max-xs:w-full">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
