import clientsData from "../../json/clients.json";
import { motion } from "framer-motion";
function MobileClients() {
  const cardVariants = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 1.5,
      },
    },
  };
  return (
    <div className="w-full h-auto px-8 py">
      <motion.div
        className="w-full"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <div className="w-full h-full overflow-hidden">
          <motion.div className="w-full" variants={cardVariants}>
            <p className="text-[2rem] text-7d-white font-blinker font-light mb-[4.5rem]">
              We are committed to delivering substantial returns on investment
              for our clients, Currently managing ad budgets of{" "}
              <span className="font-bold">3,10,000$</span> with profitability.
            </p>
          </motion.div>
        </div>
      </motion.div>
      <div className="w-full h-auto grid grid-cols-3 gap-10">
        {clientsData.map((client, i) => (
          <div
            key={i}
            className="w-full h-auto filter saturate-0 hover:saturate-100 transition duration-300"
          >
            <div className="w-[8rem] h-auto mx-auto">
              <img
                src={require(`../../images/clients/${client.src}`)}
                alt={client.name}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default MobileClients;
