import React, { Fragment, Suspense } from "react";

import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
const ProjectCardComp = React.lazy(() => import("../project-card"));

function VisualBrandingProjects({ type }) {
  const navigate = useNavigate();

  const dynamicRoute = [
    {
      slug: "sanery-constructions",
      thumbnail: "visual-branding-1.webp",
      client: "Sanery Constructions",
    },
    {
      slug: "essnar-properties",
      thumbnail: "visual-branding-2.webp",
      client: "Essnar Properties",
    },
    { slug: "lewis", thumbnail: "visual-branding-3.webp", client: "Lewis" },
    { slug: "futura", thumbnail: "visual-branding-4.webp", client: "Futura" },
    {
      slug: "nargis-dutt-foundation",
      thumbnail: "visual-branding-6.webp",
      client: "Nargis Dutt Foundation",
    },
    {
      slug: "pai-tiffins",
      thumbnail: "visual-branding-5.webp",
      client: "Pai Tiffins",
    },
    { slug: "aura", thumbnail: "visual-branding-7.webp", client: "Aura" },
    {
      slug: "champa-sweets",
      thumbnail: "visual-branding-8.webp",
      client: "Champa Sweets",
    },
    {
      slug: "kickstart",
      thumbnail: "visual-branding-10.webp",
      client: "KickStart",
    },
    { slug: "agni", thumbnail: "visual-branding-9.webp", client: "Agni" },
  ];

  const handleClick = (data) => {
    console.log(data);

    navigate(`/projects/visual-branding/${data.slug}`, { state: true });
  };

  return (
    <div className="w-full h-auto pb-24 border-b border-7d-gray-70">
      <div className="w-full h-auto">
        <h3 className="text-[3.2rem] font-blinker font-normal text-7d-green uppercase mb-[4.5rem] max-xs:mb-[1.5rem]">
          Visual Branding
        </h3>
      </div>
      <div className="grid grid-cols-2 gap-6 max-xs:grid-cols-1 max-xs:gap-6">
        {dynamicRoute.map((item, i) => (
          <Fragment key={i}>
            <Suspense fallback={<div>Loading...</div>}>
              <ProjectCardComp
                data={item}
                handleClick={handleClick}
                bgColor="bg-[#F15625]"
                type={type}
              />
            </Suspense>
          </Fragment>
        ))}
      </div>
    </div>
  );
}
export default VisualBrandingProjects;
