import ProjectGradientText from "../project-gradient-text";

function ProjectsArea() {
  return (
    <div className="w-full h-auto mb-14">
      <div className="w-full h-auto pb-20 border-b border-7d-gray-70">
        <div className="w-full h-auto">
          <ProjectGradientText id="desktopProjectHeading" />
        </div>
      </div>
    </div>
  );
}
export default ProjectsArea;
