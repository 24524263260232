import { motion } from "framer-motion";

function Insights() {
  const cardVariants = {
    offscreen: {
      y: 150,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };
  return (
    <div className="w-full h-auto my-14 pb-16 border-b border-7d-gray-70">
      <div className="w-full h-auto flex justify-between items-start mb-10">
        <div className="w-1/2 h-auto">
          <div className="w-full h-auto">
            <p className="primary-heading">Insights</p>
          </div>
        </div>
        <motion.div
          className="w-1/2 h-auto"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
        >
          <div className="w-full h-auto">
            <div className="w-full h-full overflow-hidden">
              <motion.div className="w-full" variants={cardVariants}>
                <p className="primary-heading mb-12">
                  We are committed to delivering substantial returns on
                  investment for our clients, Currently managing ad budgets of
                  3,10,000$ with profitability.
                </p>
              </motion.div>
            </div>
            <ul className="w-full">
              <li className="h-full overflow-hidden">
                <motion.p
                  variants={cardVariants}
                  className="text-[3.2rem] font-blinker font-normal text-7d-white"
                >
                  400+ Total Ads run
                </motion.p>
              </li>
              <li className="h-full overflow-hidden">
                <motion.p
                  variants={cardVariants}
                  className="text-[3.2rem] font-blinker font-normal text-7d-white"
                >
                  25+ Brands
                </motion.p>
              </li>
              <li className="h-full overflow-hidden">
                <motion.p
                  variants={cardVariants}
                  className="text-[3.2rem] font-blinker font-normal text-7d-white"
                >
                  60+ Ad Films
                </motion.p>
              </li>
              <li className="h-full overflow-hidden">
                <motion.p
                  variants={cardVariants}
                  className="text-[3.2rem] font-blinker font-normal text-7d-white"
                >
                  40M+ Reach
                </motion.p>
              </li>
            </ul>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
export default Insights;
