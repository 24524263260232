import React, { Fragment, Suspense } from "react";
import { useNavigate } from "react-router-dom";
const ProjectCardComp = React.lazy(() => import("../project-card"));

function VideoProductionProjects({ type }) {
  const navigate = useNavigate();

  const dynamicRoute = [
    {
      slug: "pai-1",
      thumbnail: "video-production-1.webp",
      client: "PAI INTERNATIONAL",
      project: "NEW YEAR SUPER SALE",
    },
    {
      slug: "pai-2",
      thumbnail: "video-production-2.webp",
      client: "PAI INTERNATIONAL",
      project: "NEW YEAR SUPER SALE",
    },
    {
      slug: "pai-3",
      thumbnail: "video-production-3.webp",
      client: "PAI INTERNATIONAL",
      project: "YAKSHAGANA MEGA FESTIVAL SALE",
    },
    {
      slug: "pai-4",
      thumbnail: "video-production-4.webp",
      client: "PAI INTERNATIONAL",
      project: "BURRAKATHA MEGA FESTIVAL SALE",
    },
    {
      slug: "pai-5",
      thumbnail: "video-production-5.webp",
      client: "PAI INTERNATIONAL",
      project: "DIWALI MEGA FESTIVAL SALE",
    },
    {
      slug: "pai-6",
      thumbnail: "video-production-6.webp",
      client: "PAI INTERNATIONAL",
      project: "DIWALI MEGA FESTIVAL SALE",
    },
    {
      slug: "pai-7",
      thumbnail: "video-production-7.webp",
      client: "PAI INTERNATIONAL",
      project: "3 SEASON SALE 2023",
    },
    {
      slug: "pai-8",
      thumbnail: "video-production-8.webp",
      client: "PAI INTERNATIONAL",
      project: "3 SEASON SALE 2023",
    },
    {
      slug: "pai-9",
      thumbnail: "video-production-9.webp",
      client: "PAI INTERNATIONAL",
      project: "3 SEASON SALE 2023",
    },
    {
      slug: "pai-10",
      thumbnail: "video-production-10.webp",
      client: "PAI INTERNATIONAL",
      project: "NEW YEAR SUPER SALE",
    },
    {
      slug: "pai-11",
      thumbnail: "video-production-11.webp",
      client: "PAI INTERNATIONAL",
      project: "NEW YEAR SUPER SALE",
    },
    {
      slug: "pai-12",
      thumbnail: "video-production-12.webp",
      client: "PAI INTERNATIONAL",
      project: "NEW YEAR SUPER SALE",
    },
    {
      slug: "pai-13",
      thumbnail: "video-production-13.webp",
      client: "PAI INTERNATIONAL",
      project: "3 SEASON SALE 2023",
    },
    {
      slug: "pai-14",
      thumbnail: "video-production-14.webp",
      client: "PAI INTERNATIONAL",
      project: "MEGA FESTIVAL SALE",
    },
    {
      slug: "pai-15",
      thumbnail: "video-production-15.webp",
      client: "PAI INTERNATIONAL",
      project: "MEGA FESTIVAL SALE",
    },
    {
      slug: "pai-16",
      thumbnail: "video-production-16.webp",
      client: "PAI INTERNATIONAL",
      project: "MEGA MONSOON MELA",
    },
    {
      slug: "pai-17",
      thumbnail: "video-production-17.webp",
      client: "PAI INTERNATIONAL",
      project: "INDEPENDENCE DAY SALE",
    },
    {
      slug: "pai-18",
      thumbnail: "video-production-18.webp",
      client: "PAI INTERNATIONAL",
      project: "MEGA MONSOON MELA",
    },
  ];

  const handleClick = (data) => {
    console.log(data);

    navigate(`/projects/video-production/${data.slug}`, { state: true });
  };

  return (
    <div className="w-full h-auto pt-12 pb-24 max-xs:border-b-0">
      <div className="w-full h-auto">
        <h3 className="text-[3.2rem] font-blinker font-normal text-7d-green uppercase mb-[4.5rem] max-xs:mb-[1.5rem]">
          Video Production
        </h3>
      </div>
      <div className="grid grid-cols-2 gap-6 max-xs:grid-cols-1 max-xs:gap-6">
        {dynamicRoute.map((item, i) => (
          <Fragment key={i}>
            <Suspense fallback={<div>Loading...</div>}>
              <ProjectCardComp
                data={item}
                handleClick={handleClick}
                bgColor="bg-[#9DCB3B]"
                type={type}
              />
            </Suspense>
          </Fragment>
        ))}
      </div>
    </div>
  );
}
export default VideoProductionProjects;
