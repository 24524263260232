import { Outlet } from "react-router-dom";
import Home from "./home";
import MobileHome from "./mobile-home";
import MobileHeader from "../components/mobile-header";
import Scrollbar from "smooth-scrollbar";
import { useEffect, useRef } from "react";

function Layout() {
  const containerRef = useRef(null);

  return (
    <div className="relative" ref={containerRef}>
      <div className="block max-xs:hidden">
        <Home />
      </div>
      <div className="hidden max-xs:block">
        <MobileHeader />
      </div>
      <Outlet />
    </div>
  );
}
export default Layout;
