import { useNavigate } from "react-router-dom";
import SevenDLogo from "../components/7dLogo";
import Button from "../components/button";
import HomeGradientText from "../components/home-gradient-text";
import BannerVideo from "../components/banner-video";
import SocialLinks from "../components/social-links";
import { useEffect, useRef } from "react";

function Home() {
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = (e) => {
      if (!checkScrollDirectionIsUp(e)) {
        console.log("Down");
        navigate("/explore", { state: true });
      }
    };
    console.log(scrollContainer);
    scrollContainer.addEventListener("wheel", handleScroll);

    function checkScrollDirectionIsUp(event) {
      if (event.wheelDelta) {
        return event.wheelDelta > 0;
      }
      return event.deltaY < 0;
    }
    return () => {
      scrollContainer.removeEventListener("wheel", handleScroll);
    };
  }, []);
  return (
    <div
      ref={scrollContainerRef}
      className="w-screen h-screen fixed top-0 left-0 right-0 bottom-0 overflow-hidden bg-7d-black bg-opacity-70"
    >
      <div className="w-full h-full relative">
        <div className="w-full h-full absolute top-0 left-0 z-[1]">
          <BannerVideo overlay="video-overlay-desktop" />
        </div>
        <div className="w-full h-full absolute top-0 left-0 z-[2]">
          <div className="w-full h-full p-[5rem]">
            <div className="w-full h-full flex flex-col justify-between items-start">
              <div className="w-full h-auto">
                <div className="w-full h-auto flex justify-between items-start">
                  <div className="w-auto h-auto sevendlogo">
                    <SevenDLogo />
                  </div>
                  <div className="w-auto">
                    <Button
                      type="button"
                      label="Explore"
                      handleClick={() => navigate("/explore", { state: true })}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full h-auto">
                <div className="w-full h-auto flex justify-between items-center">
                  <div className="w-1/3 h-auto">
                    <div className="w-full h-auto mb-8">
                      <Button
                        type="button"
                        label="Projects"
                        handleClick={() =>
                          navigate("/projects", { state: true })
                        }
                      />
                    </div>
                    <div className="w-full h-auto mb-8">
                      <div className="w-full h-auto flex justify-start items-end gap-8">
                        <div className="w-auto">
                          <Button
                            type="button"
                            label="Contact Us"
                            handleClick={() =>
                              navigate("/contact-us", { state: true })
                            }
                          />
                        </div>
                        <div className="w-auto h-auto mb-4">
                          <SocialLinks />
                        </div>
                      </div>
                    </div>
                    <div className="w-full h-auto">
                      <p className="text-[2rem] leading-[2.4rem] text-7d-white font-blinker font-normal">
                        #18, Off Millers-Nandidurga Road, Jayamahal (CBD),{" "}
                        <br /> Benson Town, Bangalore-560046, Karnataka.
                      </p>
                    </div>
                  </div>
                  <div className="w-2/3 h-auto">
                    <div className="w-full h-auto flex justify-end">
                      <HomeGradientText />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
