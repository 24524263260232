import { useState } from "react";
import Swal from 'sweetalert2'
// import { useHistory } from 'react-router-dom'; // Import useHistory
import Button from "../button";
import { InputField, TextAreaField } from "../input";
import axios from "axios";
// import axios from "axios";

function ContactForm() {
  const [formdata, setformdata] = useState();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    iconColor: 'white',
    customClass: {
      popup: 'colored-toast',
      progressBar: 'timerProgressBar',
    },
    showCloseButton:true,
    showConfirmButton: false,
    timer:2000
   
  })
  // const history = useHistory(); // Initialize useHistory

  const sendmailHandler = async function (data) {


    try{
        await axios.post('https://7dmail-server.vercel.app/7ddesign',data)
        Toast.fire({
          icon: 'success',
          title: 'Your Request Sent..!',
        }).then(()=>{
          window.location.href ='/'
        })

    }catch(error){
      Toast.fire({
        icon: 'error',
        title: 'Internal server Error...!',
      }).then(()=>{
        window.location.href ='/'
      })
    }
  };

  return (
    <>
     
      <div className="w-full h-full">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendmailHandler(formdata);
            console.log(formdata);
          }}
        >
          <InputField
            type="text"
            label="Full Name"
            name="name"
            setvalue={setformdata}
            validate={true}
          />
          <InputField
            type="number"
            label="Mobile Number"
            name="mobile"
            setvalue={setformdata}
            validate={true}
          />
          <InputField
            type="email"
            label="Email address"
            name="email"
            setvalue={setformdata}
            validate={true}
          />
          <InputField
            type="text"
            label="Company Name (Optional)"
            name="companyName"
            setvalue={setformdata}
            validate={false}
          />
          <TextAreaField
            label="Message"
            name="message"
            setvalue={setformdata}
            validate={true}
          />
          <div className="mt-10">
            <Button type="submit" label="Submit" />
          </div>
        </form>
        <div className="w-full h-auto mt-[5%] hidden max-xs:block">
          <div className="w-full h-auto">
            {/* <p className="primary-heading">digital@7ddesign.in</p> */}
            <p className="primary-heading">+91 9496128372</p>
          </div>
        </div>
      </div>
      
    </>
  );
}
export default ContactForm;
