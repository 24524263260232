import DesktopProjects from "./desktop-projects";
import MobileProjects from "./mobile-projects";
import { useNavigate, useLocation } from "react-router";

function Projects() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = () => {
    console.log(location.pathname);
    if (location.pathname == "/projects") {
      navigate("/");
    } else {
      navigate("/projects");
    }
  };

  return (
    <>
      <div className="block max-xs:hidden">
        <DesktopProjects />
      </div>
      <div className="hidden max-xs:block">
        <MobileProjects />
        <div className="w-auto h-auto hidden max-xs:block z-[99999] fixed bottom-[3%] right-[6%] ">
          <div className="w-[4rem] h-auto" onClick={handleNavigate}>
            <img src={require("../images/mobile-close.png")} />
          </div>
        </div>
      </div>
    </>
  );
}
export default Projects;
