import Contact from "../components/contact";
import MobileFooter from "../components/mobile-footer";
import MobileQuickLinks from "../components/mobile-quick-link";
import { useEffect } from "react";

function MobileContactUs() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="w-full h-auto bg-7d-gray-20 z-[5]">
      <div className="w-full h-full pt-[13vh] px-8 overflow-auto">
        <Contact />
        <MobileFooter />
      </div>
    </div>
  );
}
export default MobileContactUs;
