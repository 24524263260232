import SocialLinks from "../social-links";

function MobileFooter() {
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto px-6 pt-10 pb-20">
        <p className="text-[2rem] font-blinker font-extralight mb-10">
          #18, Off Millers-Nandidurga Road, <br /> Jayamahal (CBD), Benson Town,{" "}
          <br /> Bangalore-560046, Karnataka.
        </p>
        <div className="w-full h-auto mb-10">
          <SocialLinks />
        </div>
        <p className="text-[2rem] font-blinker font-extralight">
          Copyright © 7D Design
        </p>
      </div>
    </div>
  );
}
export default MobileFooter;
