import {Link} from "react-router-dom"
function NotFound(){
    return(
        <div className="w-full h-screen flex flex-col justify-center items-center bg-7d-gray-20">
            <h3 className="text-[10rem] font-bold text-7d-orange">OOPS!</h3>
            <h3 className="text-[3rem] text-7d-white mb-[5%]">We can't find the page you are looking for!</h3>
            <Link to="/" className="text-[2.2rem] text-7d-white underline">Go to Home</Link>
        </div>
    )
}
export default NotFound;