import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ProjectsArea from "../components/projects";
import VisualBrandingProjects from "../components/visual-branding-projects";
import VideoProductionProjects from "../components/video-production-projects";
import Contact from "../components/contact";
import { useEffect, useRef, useState } from "react";
import {
  motion,
  AnimatePresence,
  usePresence,
  useScroll,
  useTransform,
} from "framer-motion";
import { gsap } from "gsap";
import ProjectsBanner from "../components/projects-banner";

function DesktopProjects() {
  const navigate = useNavigate();
  const location = useLocation();

  const sideContainer = useRef();
  const [isExiting, setIsExiting] = useState(location.state);
  const [isPresent, safeToRemove] = usePresence();
  const ref = useRef(null);

  useEffect(() => {
    console.log(location.pathname);
    const handleClickOutside = (event) => {
      if (location.pathname === "/projects") {
        if (
          sideContainer.current &&
          !sideContainer.current.contains(event.target)
        ) {
          if (!isPresent) {
            setIsExiting(true);

            console.log("Rare scenerio...!");
          } else {
            setIsExiting(false);

            gsap.to(ref.current, {
              opacity: 1,
              duration: 1,
              ease: [0.43, 0.13, 0.23, 0.96],
              onComplete: () => {
                navigate("/");
              },
            });
          }
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [location.pathname]);

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "0vw",
    },
    in: {
      opacity: 1,
      x: 0,
    },
  };

  const scrollref = useRef(null);

  const { scrollYProgress } = useScroll({
    container: scrollref,
  });

  const translateY = useTransform(scrollYProgress, [0, 1], ["25%", "-25%"]);

  return (
    <>
      <AnimatePresence>
        {isExiting ? (
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={{ duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] }}
            ref={ref}
            className="w-screen h-screen fixed top-0 right-0 bg-7d-dark bg-opacity-70"
          >
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{
                delay: 0.3,
                duration: 0.8,
                ease: [0.43, 0.13, 0.23, 0.96],
              }}
              className="w-[74%] max-3xl:w-[73.5%] h-full ml-auto rounded-tl-[1rem] bg-7d-gray-20 page-close"
              ref={sideContainer}
            >
              <div className="w-full h-full p-[4.5rem] pt-[3.5rem] pr-0">
                <div
                  className="w-full h-full pr-[4.5rem] overflow-auto"
                  ref={scrollref}
                >
                  <ProjectsArea />
                  <VisualBrandingProjects type="desktop" />
                  <VideoProductionProjects type="desktop" />
                  <ProjectsBanner translateY={translateY} />
                  <Contact />
                </div>
              </div>
            </motion.div>
          </motion.div>
        ) : (
          ""
        )}
      </AnimatePresence>
      <Outlet />
    </>
  );
}
export default DesktopProjects;
