import AboutUsBanner from "../about-us-banner";
import { motion } from "framer-motion";

function AboutUs({ translateY }) {
  const cardVariants = {
    offscreen: {
      y: 150,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        // bounce: 0.3,
        duration: 1,
      },
    },
  };
  return (
    <div className="w-full h-auto my-14">
      <div className="w-full h-auto flex justify-between items-start mb-10">
        <div className="w-1/2 h-auto">
          <div className="w-full h-auto">
            <p className="primary-heading">About Us</p>
          </div>
        </div>
        <motion.div
          className="w-1/2 h-auto"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
        >
          <div className="w-full h-auto">
            <div className="w-full h-full overflow-hidden">
              <motion.div className="w-full" variants={cardVariants}>
                <p className="primary-heading mb-12">
                  We are an Integrated Marketing & Branding Agency that can tell
                  your brand story
                </p>
              </motion.div>
            </div>
            <div className="w-full h-full overflow-hidden">
              <motion.div className="w-full" variants={cardVariants}>
                <p className="text-[3.2rem] font-blinker font-normal text-7d-white">
                  We work with e-commerce brands, Corporate brands, FMCG,
                  Hospitals, Retail, IT sector & Govt. sectors
                </p>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
      <AboutUsBanner translateY={translateY} />
    </div>
  );
}
export default AboutUs;
