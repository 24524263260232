import { useNavigate, useParams } from "react-router-dom";

function VisualBrandingDetail() {
  const navigate = useNavigate();

  const dynamicRoute = [
    {
      slug: "sanery-constructions",
      thumbnail: "visual-branding-detail-1.webp",
      pills: [{ label: "Logo Design" }, { label: "Stationary-kit Design" }],
      name: "Sanery Constructions",
      content:
        "A prestigious contracting and interiors design company with a rich legacy spanning four generations. Our design brief was to create a logo that follows the current trend in the construction and real estate industry, while also reflecting the personal touch of the owner and his family. The wordmark is the fusion of the owner’s name, Sandesh, and his kid’s name, Reyansh, hence SANREY. The letter N and R are fused in such a way that they indicate an arrow of growth pointing upwards, symbolizing the company’s vision and values.",
    },
    {
      slug: "essnar-properties",
      thumbnail: "visual-branding-detail-2.webp",
      pills: [{ label: "Logo Design" }, { label: "Stationary-kit Design" }],
      name: "Essnar Properties",
      content:
        "A leading real estate company that offers a range of services, from plotted developments and land development to property transactions. The company wanted to create an identity system that reflects its international standards and its vision of providing one-stop solutions for all real estate needs. The logomark for Essenar Group is a fusion of three elements: angles in construction, measurement scale, and growth arrow. These elements symbolize the company’s expertise, quality, and ambition in the real estate sector. The logo mark also has a dynamic and modern look that appeals to the target audience.",
    },
    {
      slug: "lewis",
      thumbnail: "visual-branding-detail-3.webp",
      pills: [
        { label: "Logo Design" },
        { label: "Stationary-kit Design" },
        { label: "Marketing" },
      ],
      name: "Lewis",
      content:
        "A group of companies that offer various services in the fields of infrastructure, construction, co-workingspace, and interior design. To create a unified identity system for all the sister companies, we designed a logo mark that reflects their heritage and European style. The logo mark is a fusion of the letter L and a European style pillar, symbolizing strength, elegance, and innovation. The logo mark is consistent across all the companies, while the colours and fonts vary to suit their specific domains.",
    },
    {
      slug: "futura",
      thumbnail: "visual-branding-detail-4.webp",
      pills: [{ label: "Packing Design" }],
      name: "Futura",
      content:
        "Leading lighting solutions company that offers affordable, mid-range and luxury lighting products for different customer segments. Our challenge was to design packaging that would cater to all these sections and reflect the quality and value of the products. We considered various parameters such as material, universal standards, colour, weight, and compact design while designing the packaging. We also followed a rigorous process from designing, prototyping to delivering the final product. The entire design process was based on research and market study to ensure customer satisfaction and brand loyalty.",
    },
    {
      slug: "nargis-dutt-foundation",
      thumbnail: "visual-branding-detail-6.webp",
      pills: [{ label: "Logo Design" }, { label: "Stationary-kit Design" }],
      name: "Nargis Dutt Foundation",
      content:
        "35 years old NGO for heart cancer patients run by the Dutt family. The logo is a heart-shaped gradient with the letters N and D. The heart symbolizes the foundation’s compassion, the gradient shows its diversity of initiatives, and the letters honor the name of Nargis Dutt. The logo is a unique and memorable identity for the foundation.",
    },
    {
      slug: "pai-tiffins",
      thumbnail: "visual-branding-detail-5.webp",
      pills: [{ label: "Logo Design" }, { label: "Stationary-kit Design" }],
      name: "Pai Tiffins",
      content:
        "A Pure Veg Multicuisine Restaurant, draws its inspiration from the divine essence of Lord Ganesha, the graceful symbolism of the lotus, and the letters P and T. The typeface is custom-made using the logo mark. The logo uses the traditional Indian colors of haldi and kumkum. The logo represents PAI TIFFINS’ mission to deliver satisfying and authentic Indian food.",
    },
    {
      slug: "aura",
      thumbnail: "visual-branding-detail-7.webp",
      pills: [{ label: "Logo Design" }, { label: "Stationary-kit Design" }],
      name: "Aura",
      content:
        "An exclusive brand for premium lighting and India’s biggest store to house the largest collection of leading European lighting brands. Our brief was to create a logo that reflects the high-end and luxurious quality of the brand. The logo follows the golden ratio as a form factor, using proportionate lines and circles. The custom curved font uses English and Kannada languages to create a luxury feel. The logo combines the font and the shape, creating a unique identity for AURA.",
    },
    {
      slug: "champa-sweets",
      thumbnail: "visual-branding-detail-8.webp",
      pills: [
        { label: "Logo Design" },
        { label: "Packing Design" },
        { label: "Stationary-kit Design" },
      ],
      name: "Champa Sweets",
      content:
        "A brand that has been serving handmade sweets for the last decade. The founder, is a passionate and skilled sweet maker who has a loyal customer base. To create a mascot logo, we designed a portrait of the owner’s mom with a smile and traditional attire. It also incorporates a symbol of the fusion of letters C and S, representing the name of the brand and the quality of the sweets. The logo is simple, elegant, and memorable.",
    },
    {
      slug: "kickstart",
      thumbnail: "visual-branding-detail-10.webp",
      pills: [{ label: "Rebranding" }],
      name: "KickStart",
      content:
        "A football training academy and ground rentals service that aims to inspire the new generation of players and fans. We rebranded their existing logo to make it more youth-oriented and trendy, while keeping their clear vision intact. We used the concept of movement and flow to create a dynamic and energetic logo that reflects the passion and excitement of football. We also tweaked the font to give it a sense of motion and direction. With grass texture in the background the entire logo is complete set of trendy design.",
    },
    {
      slug: "agni",
      thumbnail: "visual-branding-detail-9.webp",
      pills: [{ label: "Logo Design" }, { label: "Stationary-kit Design" }],
      name: "Agni",
      content:
        "An IT consulting firm that aims to serve the corporate market. The logo is based on the typeface AGNI, which follows the golden ratio for form and function. The logo also incorporates a hidden graph arrow in the letter A, which symbolizes the growth potential of the firm. The color scheme of blue and red reflects the vibrancy and dynamism of the IT industry.",
    },
  ];

  const { slug } = useParams();

  const selectedRoute = dynamicRoute.find((route) => route.slug === slug);

  if (!selectedRoute) {
    return <div>No data found for this slug.</div>;
  }

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-between max-xs:flex-col-reverse max-xs:gap-8">
        <div className="w-1/2 h-auto ps-[4.5rem] pt-[5rem] max-xs:w-full max-xs:pb-[20vh] max-xs:ps-0">
          <div className="w-full h-full flex flex-col justify-between">
            <div className="w-[90%] h-auto max-xs:w-full">
              <h1 className="text-[3.6rem] font-blinker font-semibold uppercase text-7d-orange mb-[5%]">
                {selectedRoute.name}
              </h1>
              <p className="text-[2rem] text-7d-white font-light font-blinker max-xs:text-[1.8rem]">
                {selectedRoute.content}
              </p>
            </div>
            <div className="w-full h-auto max-xs:mt-8">
              <div className="w-full h-auto">
                <ul className="w-full">
                  {selectedRoute.pills.map((item, i) => (
                    <li
                      key={i}
                      className="text-[3.2rem] max-xs:text-[2.4rem] font-blinker font-normal text-7d-lightorange"
                    >
                      {item.label}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 h-auto max-xs:w-full">
          <div className="w-full h-auto">
            <div className="w-full h-auto">
              <img
                src={require(`../images/${selectedRoute.thumbnail}`)}
                alt="About Us Banner"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VisualBrandingDetail;
