import { useNavigate } from "react-router-dom";
import Contact from "../components/contact";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { motion, AnimatePresence, usePresence } from "framer-motion";
import { gsap } from "gsap";
function DesktopContactUs() {
  const location = useLocation();
  const navigate = useNavigate();

  const sideContainer = useRef();
  const [isExiting, setIsExiting] = useState(location.state);
  const [isPresent, safeToRemove] = usePresence();
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sideContainer.current &&
        !sideContainer.current.contains(event.target)
      ) {
        if (!isPresent) {
          setIsExiting(true);

          console.log("Rare scenerio...!");
        } else {
          setIsExiting(false);

          gsap.to(ref.current, {
            opacity: 1,
            // delay: 0.3,
            duration: 1,
            ease: [0.43, 0.13, 0.23, 0.96],
            onComplete: () => {
              navigate("/");
            },
          });
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "0vw",
    },
    in: {
      opacity: 1,
      x: 0,
    },
  };
  return (
    <AnimatePresence>
      {isExiting ? (
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={{ duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] }}
          ref={ref}
          className="w-screen h-screen fixed top-0 right-0 bg-7d-dark bg-opacity-70"
        >
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{
              delay: 0.3,
              duration: 1,
              ease: [0.43, 0.13, 0.23, 0.96],
            }}
            className="w-[74%] max-3xl:w-[73.5%] h-full ml-auto rounded-tl-[1rem] bg-7d-gray-20 page-close"
            ref={sideContainer}
          >
            <div className="w-full h-full p-[4.5rem] pt-[4rem] pr-0">
              <div className="w-full h-full pr-[4.5rem] overflow-auto">
                <Contact />
              </div>
            </div>
          </motion.div>
        </motion.div>
      ) : (
        ""
      )}
    </AnimatePresence>
  );
}
export default DesktopContactUs;
