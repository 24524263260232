import { useNavigate, useParams } from "react-router";

function VideoProductionDetail() {
  const navigate = useNavigate();

  const dynamicRoute = [
    {
      slug: "pai-1",
      videoLink:
        "https://www.youtube.com/embed/1i7m_xlUcKg?si=KISQ8kCoR6eiQlZ6",
      client: "PAI INTERNATIONAL",
      project: "NEW YEAR SUPER SALE",
    },
    {
      slug: "pai-2",
      videoLink:
        "https://www.youtube.com/embed/6J8Zw3FfHdU?si=L3A2IW1eqm-y2LZ4",
      client: "PAI INTERNATIONAL",
      project: "NEW YEAR SUPER SALE",
    },
    {
      slug: "pai-3",
      videoLink:
        "https://www.youtube.com/embed/8Xpj2FaHU9E?si=HHomIqbCM0NkzRlk",
      client: "PAI INTERNATIONAL",
      project: "YAKSHAGANA MEGA FESTIVAL SALE",
    },
    {
      slug: "pai-4",
      videoLink:
        "https://www.youtube.com/embed/PQwqeSsdIsA?si=jlUHVNeH3qJ0zBOo",
      client: "PAI INTERNATIONAL",
      project: "BURRAKATHA MEGA FESTIVAL SALE",
    },
    {
      slug: "pai-5",
      videoLink:
        "https://www.youtube.com/embed/LH_vBpYOCSI?si=ZIjQgEqTINpyV5TU",
      client: "PAI INTERNATIONAL",
      project: "DIWALI MEGA FESTIVAL SALE",
    },
    {
      slug: "pai-6",
      videoLink:
        "https://www.youtube.com/embed/YXHGSkgpk7I?si=nKY9FxcZ9cGPa55b",
      client: "PAI INTERNATIONAL",
      project: "DIWALI MEGA FESTIVAL SALE",
    },
    {
      slug: "pai-7",
      videoLink:
        "https://www.youtube.com/embed/BGhIoELKmJQ?si=MtsXjoeq_-zer9jA",
      client: "PAI INTERNATIONAL",
      project: "3 SEASON SALE 2023",
    },
    {
      slug: "pai-8",
      videoLink:
        "https://www.youtube.com/embed/6rdTFlHp3Bs?si=kfjZyKXgtBc2ZP6o",
      client: "PAI INTERNATIONAL",
      project: "3 SEASON SALE 2023",
    },
    {
      slug: "pai-9",
      videoLink:
        "https://www.youtube.com/embed/Tli2NVVqk3s?si=wwLdxJauIk79WM-q",
      client: "PAI INTERNATIONAL",
      project: "3 SEASON SALE 2023",
    },
    {
      slug: "pai-10",
      videoLink:
        "https://www.youtube.com/embed/s5BwOQZOgHo?si=R1ICqvWtaU6Bg4i-",
      client: "PAI INTERNATIONAL",
      project: "NEW YEAR SUPER SALE",
    },
    {
      slug: "pai-11",
      videoLink:
        "https://www.youtube.com/embed/3vVsmHkdRfY?si=DhuMUIYvfHA_JLth",
      client: "PAI INTERNATIONAL",
      project: "NEW YEAR SUPER SALE",
    },
    {
      slug: "pai-12",
      videoLink:
        "https://www.youtube.com/embed/Q0Wjy1jCQHE?si=F0N9LFFsdE_I2RNT",
      client: "PAI INTERNATIONAL",
      project: "NEW YEAR SUPER SALE",
    },
    {
      slug: "pai-13",
      videoLink:
        "https://www.youtube.com/embed/BVhFWh44rQw?si=gcqHfcIqAhbDlKG9",
      client: "PAI INTERNATIONAL",
      project: "3 SEASON SALE 2023",
    },
    {
      slug: "pai-14",
      videoLink:
        "https://www.youtube.com/embed/1D9FcEuK5t8?si=_ZabckUCT4UeKWnL",
      client: "PAI INTERNATIONAL",
      project: "MEGA FESTIVAL SALE",
    },
    {
      slug: "pai-15",
      videoLink:
        "https://www.youtube.com/embed/1rxNQtzJazc?si=1ZUbogiSZvXOMZTJ",
      client: "PAI INTERNATIONAL",
      project: "MEGA FESTIVAL SALE",
    },
    {
      slug: "pai-16",
      videoLink:
        "https://www.youtube.com/embed/uo0r8Q3DPkU?si=NBC5qqiaa4t0oiZP",
      client: "PAI INTERNATIONAL",
      project: "MEGA MONSOON MELA",
    },
    {
      slug: "pai-17",
      videoLink:
        "https://www.youtube.com/embed/NY38mh9rdu8?si=ExlORZV3tQr7i1oB",
      client: "PAI INTERNATIONAL",
      project: "INDEPENDENCE DAY SALE",
    },
    {
      slug: "pai-18",
      videoLink:
        "https://www.youtube.com/embed/sBcafyyMc9s?si=YkdSM9_f99pwZKqY",
      client: "PAI INTERNATIONAL",
      project: "MEGA MONSOON MELA",
    },
  ];

  const { slug } = useParams();

  const selectedRoute = dynamicRoute.find((route) => route.slug === slug);

  if (!selectedRoute) {
    return <div>No data found for this slug.</div>;
  }
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto mb-8">
        <iframe
          className="w-full aspect-video"
          src={`${selectedRoute.videoLink}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write;  picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="w-full h-auto">
        <h2 className="text-[3.6rem] max-xs:text-[2.5rem] font-blinker font-semibold text-7d-green uppercase">
          {selectedRoute.client}
        </h2>
        <h2 className="text-[3.6rem] max-xs:text-[2.5rem] font-blinker font-semibold text-7d-green uppercase">
          {selectedRoute.project}
        </h2>
      </div>
    </div>
  );
}
export default VideoProductionDetail;
