import { useState } from "react";
import ReactPlayer from "react-player/vimeo";
import Logo from '../../images/7dDesignLogo.png'
function BannerVideo({ overlay }) {
  const [isLoading, setIsLoading] = useState(true);

  const handleCanPlay = () => {
    setIsLoading(false);
  };
  return (
    <div className={`w-full h-full relative video-overlay ${overlay}`}>
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-800">
          {/* Replace with your loader component */}
          <div className="loader">
            <img src={Logo} alt="logo" />
          </div>
        </div>
      )}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="w-full h-full object-cover"
        width="1920"
        onCanPlay={handleCanPlay}
      >
        <source
          src={require("../../videos/new.mp4")}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      {/* <div className="w-full h-full object-cover"> */}
        {/* <ReactPlayer
          url="https://vimeo.com/1001685516"
          playing
          loop
          width="100%"
          height="100%"
          // muted={false}
          config={{
            vimeo: {
              playerOptions: {
                autoplay: true,
                background:true,
                loop: true,
              },
            },
          }}
        /> */}
      {/* </div> */}
    </div>
  );
}
export default BannerVideo;
