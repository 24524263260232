import { motion, AnimatePresence } from "framer-motion";

const Accordion = ({ i, data, expanded, setExpanded, type }) => {
  const isOpen = i === expanded;
  const groupHoverColor = data.color;
  const cardVariants = {
    offscreen: {
      y: 100,
    },
    onscreen: {
      y: 0,
      transition: {
        duration: 1,
      },
    },
  };
  return (
    <div className="w-full h-auto bg-7d-gray-20 max-xs:bg-transparent">
      <motion.div
        initial={false}
        animate={{ color: isOpen ? data.activeColor : "#ffffff" }}
        onClick={() => setExpanded(isOpen ? false : i)}
        className="w-full h-auto cursor-pointer group"
      >
        <motion.div
          className="w-full h-auto flex justify-between items-center text-inherit"
          initial="offscreen"
          whileInView="onscreen"
        >
          <div className="w-full h-full overflow-hidden">
            <motion.div
              className="w-full h-auto flex justify-start items-center gap-5 text-inherit"
              variants={cardVariants}
            >
              <div className="w-8 h-auto mr-8">
                <svg
                  width="32"
                  height="32"
                  className="transform group-hover:rotate-180 transition duration-300"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 32C15.5368 16.8419 15.1581 16.4656 0 16C15.1581 15.5368 15.5344 15.1581 16 0C16.4632 15.1581 16.8419 15.5344 32 16C16.8419 16.4656 16.4656 16.8373 16 32Z"
                    fill={`url(#${data.id + type})`}
                  />
                  <defs>
                    <linearGradient
                      id={data.id + type}
                      x1="31.1165"
                      y1="15.9465"
                      x2="0.294474"
                      y2="15.9465"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.31" stopColor="#F15625" />
                      <stop offset="0.435" stopColor="#5093AB" />
                      <stop offset="0.595" stopColor="#9DCB3B" />
                      <stop offset="0.74" stopColor="#E9E748" />
                      <stop offset="1" stopColor="#282828" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <p
                className={`text-[4.8rem] font-blinker font-normal text-inherit max-xs:text-[3.2rem] ${groupHoverColor}`}
              >
                {data.title}
              </p>
            </motion.div>
          </div>

          <div className="w-[3rem] hidden max-xs:block">
            <div className="w-[1.7rem] h-auto">
              <img
                src={require("../../images/quicklinks-arrow.png")}
                alt="Quick Links Arrow"
              />
            </div>
          </div>
        </motion.div>
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: {
                opacity: 1,
                height: type == "desktop" ? "43rem" : "auto",
              },
              collapsed: { opacity: 0, height: 0 },
            }}
            className="w-full h-auto flex flex-col justify-between"
          >
            <p className="text-[3rem] font-blinker font-normal text-7d-lightorange max-md:text-[2rem] max-xs:text-[1.8rem]">
              {data.contenttitle}
            </p>
            <div key={i} className="w-full h-auto my-10 hidden max-xs:block">
              <img src={require(`../../images/${data.imgsrc}`)} alt="" />
            </div>
            <p className="text-[3rem] font-blinker font-normal text-7d-white max-md:text-[2rem] max-xs:text-[1.8rem]">
              {data.contentTags}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export const ServiceAccordion = ({
  accordionContent,
  expanded,
  setExpanded,
  type,
}) => {
  return accordionContent.map((data, i) => (
    <Accordion
      i={i}
      data={data}
      expanded={expanded}
      setExpanded={setExpanded}
      type={type}
    />
  ));
};
