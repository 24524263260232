function SocialLinks() {
  return (
    <div className="w-ull h-auto flex justify-start items-center gap-8">
      <div className="w-[3rem] h-[3rem]">
        <a href="https://www.instagram.com/7ddesignagency/" target="_blank">
          <img src={require("../../images/instagram.png")} alt="Facebook" />
        </a>
      </div>
      <div className="w-[3rem] h-[3rem]">
        <a
          href="https://www.linkedin.com/company/7d-design-agency"
          target="_blank"
        >
          <img src={require("../../images/linkedin.png")} alt="Facebook" />
        </a>
      </div>
      <div className="w-[3rem] h-[3rem]">
        <a
          href="https://www.youtube.com/channel/UCGvE-t8Hi1U5FEkAZsTpPGQ"
          target="_blank"
        >
          <img src={require("../../images/youtube.png")} alt="Facebook" />
        </a>
      </div>
      <div className="w-[3rem] h-[3rem]">
        <a href="https://www.facebook.com/7ddesign.in/" target="_blank">
          <img src={require("../../images/facebook.png")} alt="Facebook" />
        </a>
      </div>
    </div>
  );
}
export default SocialLinks;
