import insightsData from "../../json/insights.json";
import { motion } from "framer-motion";
function MobileInsights() {
  const cardVariants = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 1.5,
      },
    },
  };
  console.log(insightsData);
  return (
    <div className="w-full h-auto">
      <motion.div
        className="w-full h-auto px-8 py-[5rem]"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <div className="w-full h-full overflow-hidden">
          <motion.div className="w-full" variants={cardVariants}>
            <div className="w-full h-auto">
              <div className="w-full h-auto flex justify-start items-center gap-4">
                <h4 className="text-[4rem] font-blinker font-bold text-7d-orange">
                  {insightsData.totalAdsRun}+
                </h4>
                <p className="text-[4rem] font-blinker font-light text-7d-white">
                  Total Ads run
                </p>
              </div>
              <div className="w-full h-auto flex justify-start items-center gap-4">
                <h4 className="text-[4rem] font-blinker font-bold text-7d-orange">
                  {insightsData.brands}+
                </h4>
                <p className="text-[4rem] font-blinker font-light text-7d-white">
                  Brands
                </p>
              </div>
              <div className="w-full h-auto flex justify-start items-center gap-4">
                <h4 className="text-[4rem] font-blinker font-bold text-7d-orange">
                  {insightsData.adFlims}+
                </h4>
                <p className="text-[4rem] font-blinker font-light text-7d-white">
                  Ad Films
                </p>
              </div>
              <div className="w-full h-auto flex justify-start items-center gap-4">
                <h4 className="text-[4rem] font-blinker font-bold text-7d-orange">
                  {insightsData.reach}+
                </h4>
                <p className="text-[4rem] font-blinker font-light text-7d-white">
                  Reach
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}
export default MobileInsights;
