function HomeGradientText() {
    return (
        <svg className="w-[108.4rem] max-md:w-[50rem]" viewBox="0 0 1084 186" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M374.188 83.25L365.328 0.75H381.453L386 52.8281L390.641 0.75H406.016L410.422 52.8281L414.828 0.75H431.141L422.141 83.25H402.359L398.234 43.5469L394.297 83.25H374.188Z" fill="url(#paint0_linear_113_96)" />
            <path d="M436.062 83.25V0.75H469.062V16.6875H452.844V32.7656H468.406V48.2812H452.844V67.1719H470.141V83.25H436.062Z" fill="url(#paint1_linear_113_96)" />
            <path d="M498.125 83.25V0.75H520.812C526.75 0.75 531.219 2.40625 534.219 5.71875C537.219 9 538.719 13.8125 538.719 20.1562V58.7812C538.719 66.5938 537.344 72.625 534.594 76.875C531.875 81.125 527.094 83.25 520.25 83.25H498.125ZM514.719 68.6719H517.578C520.609 68.6719 522.125 67.2031 522.125 64.2656V21.7031C522.125 18.9531 521.75 17.1875 521 16.4062C520.281 15.5938 518.797 15.1875 516.547 15.1875H514.719V68.6719Z" fill="url(#paint2_linear_113_96)" />
            <path d="M545.469 83.25V0.75H578.469V16.6875H562.25V32.7656H577.812V48.2812H562.25V67.1719H579.547V83.25H545.469Z" fill="url(#paint3_linear_113_96)" />
            <path d="M604.625 84C597.094 84 591.656 82.125 588.312 78.375C585 74.625 583.344 68.6562 583.344 60.4688V52.4062H599.656V62.7188C599.656 64.625 599.938 66.125 600.5 67.2188C601.094 68.2812 602.109 68.8125 603.547 68.8125C605.047 68.8125 606.078 68.375 606.641 67.5C607.234 66.625 607.531 65.1875 607.531 63.1875C607.531 60.6562 607.281 58.5469 606.781 56.8594C606.281 55.1406 605.406 53.5156 604.156 51.9844C602.938 50.4219 601.234 48.6094 599.047 46.5469L591.641 39.5156C586.109 34.2969 583.344 28.3281 583.344 21.6094C583.344 14.5781 584.969 9.21875 588.219 5.53125C591.5 1.84375 596.234 0 602.422 0C609.984 0 615.344 2.01562 618.5 6.04688C621.688 10.0781 623.281 16.2031 623.281 24.4219H606.5V18.75C606.5 17.625 606.172 16.75 605.516 16.125C604.891 15.5 604.031 15.1875 602.938 15.1875C601.625 15.1875 600.656 15.5625 600.031 16.3125C599.438 17.0312 599.141 17.9688 599.141 19.125C599.141 20.2812 599.453 21.5312 600.078 22.875C600.703 24.2188 601.938 25.7656 603.781 27.5156L613.297 36.6562C615.203 38.4688 616.953 40.3906 618.547 42.4219C620.141 44.4219 621.422 46.7656 622.391 49.4531C623.359 52.1094 623.844 55.3594 623.844 59.2031C623.844 66.9531 622.406 73.0312 619.531 77.4375C616.688 81.8125 611.719 84 604.625 84Z" fill="url(#paint4_linear_113_96)" />
            <path d="M628.625 83.25V0.75H644.562V83.25H628.625Z" fill="url(#paint5_linear_113_96)" />
            <path d="M668.141 84C656.359 84 650.469 75.5625 650.469 58.6875V23.1094C650.469 7.70312 657.219 0 670.719 0C676.281 0 680.547 1.125 683.516 3.375C686.484 5.59375 688.531 8.84375 689.656 13.125C690.781 17.4062 691.344 22.625 691.344 28.7812H675.219V21.4688C675.219 19.6875 674.969 18.2031 674.469 17.0156C674 15.7969 673 15.1875 671.469 15.1875C669.562 15.1875 668.297 15.8281 667.672 17.1094C667.078 18.3906 666.781 19.7969 666.781 21.3281V61.3125C666.781 63.5312 667.062 65.3438 667.625 66.75C668.219 68.125 669.359 68.8125 671.047 68.8125C672.797 68.8125 673.953 68.125 674.516 66.75C675.109 65.3438 675.406 63.5 675.406 61.2188V49.4531H671V35.2031H691.156V83.25H684.547L681.734 76.3125C678.859 81.4375 674.328 84 668.141 84Z" fill="url(#paint6_linear_113_96)" />
            <path d="M697.719 83.25V0.75H714.688L722.281 40.2656V0.75H738.219V83.25H722.094L713.844 42V83.25H697.719Z" fill="url(#paint7_linear_113_96)" />
            <path d="M787.25 84C781.281 84 776.469 82.1406 772.812 78.4219C769.188 74.7031 767.375 69.5 767.375 62.8125V24.75C767.375 16.75 768.953 10.625 772.109 6.375C775.297 2.125 780.469 0 787.625 0C791.531 0 795 0.71875 798.031 2.15625C801.094 3.59375 803.5 5.75 805.25 8.625C807 11.4688 807.875 15.0625 807.875 19.4062V33.6562H791.469V21.4688C791.469 19 791.156 17.3438 790.531 16.5C789.906 15.625 788.938 15.1875 787.625 15.1875C786.094 15.1875 785.062 15.75 784.531 16.875C784 17.9688 783.734 19.4375 783.734 21.2812V62.5781C783.734 64.8594 784.062 66.4688 784.719 67.4062C785.406 68.3438 786.375 68.8125 787.625 68.8125C789.031 68.8125 790.016 68.2344 790.578 67.0781C791.172 65.9219 791.469 64.4219 791.469 62.5781V47.7188H808.062V63.3281C808.062 70.5781 806.234 75.8438 802.578 79.125C798.922 82.375 793.812 84 787.25 84Z" fill="url(#paint8_linear_113_96)" />
            <path d="M813.594 83.25V0.75H838.906C843.125 0.75 846.312 1.71875 848.469 3.65625C850.625 5.5625 852.062 8.25 852.781 11.7188C853.531 15.1562 853.906 19.2031 853.906 23.8594C853.906 28.3594 853.328 31.9531 852.172 34.6406C851.047 37.3281 848.906 39.1875 845.75 40.2188C848.344 40.75 850.156 42.0469 851.188 44.1094C852.25 46.1406 852.781 48.7812 852.781 52.0312V83.25H836.516V50.9531C836.516 48.5469 836.016 47.0625 835.016 46.5C834.047 45.9062 832.469 45.6094 830.281 45.6094V83.25H813.594ZM830.375 31.3125H834.359C836.641 31.3125 837.781 28.8281 837.781 23.8594C837.781 20.6406 837.531 18.5312 837.031 17.5312C836.531 16.5312 835.594 16.0312 834.219 16.0312H830.375V31.3125Z" fill="url(#paint9_linear_113_96)" />
            <path d="M859.344 83.25V0.75H892.344V16.6875H876.125V32.7656H891.688V48.2812H876.125V67.1719H893.422V83.25H859.344Z" fill="url(#paint10_linear_113_96)" />
            <path d="M896.656 83.25L904.625 0.75H932.609L940.438 83.25H924.828L923.656 69.9375H913.719L912.688 83.25H896.656ZM914.891 56.7656H922.391L918.781 14.8125H918.031L914.891 56.7656Z" fill="url(#paint11_linear_113_96)" />
            <path d="M951.359 83.25V16.5938H941.562V0.75H977.656V16.5938H967.859V83.25H951.359Z" fill="url(#paint12_linear_113_96)" />
            <path d="M981.5 83.25V0.75H997.438V83.25H981.5Z" fill="url(#paint13_linear_113_96)" />
            <path d="M1011.59 83.25L1001.42 0.703125H1017.12L1023.17 56.8594L1028.56 0.703125H1044.27L1034.09 83.25H1011.59Z" fill="url(#paint14_linear_113_96)" />
            <path d="M1049.09 83.25V0.75H1082.09V16.6875H1065.88V32.7656H1081.44V48.2812H1065.88V67.1719H1083.17V83.25H1049.09Z" fill="url(#paint15_linear_113_96)" />
            <path d="M20 185C14.0312 185 9.21875 183.141 5.5625 179.422C1.9375 175.703 0.125 170.5 0.125 163.812V125.75C0.125 117.75 1.70312 111.625 4.85938 107.375C8.04688 103.125 13.2188 101 20.375 101C24.2812 101 27.75 101.719 30.7812 103.156C33.8438 104.594 36.25 106.75 38 109.625C39.75 112.469 40.625 116.062 40.625 120.406V134.656H24.2188V122.469C24.2188 120 23.9062 118.344 23.2812 117.5C22.6562 116.625 21.6875 116.188 20.375 116.188C18.8438 116.188 17.8125 116.75 17.2812 117.875C16.75 118.969 16.4844 120.438 16.4844 122.281V163.578C16.4844 165.859 16.8125 167.469 17.4688 168.406C18.1562 169.344 19.125 169.812 20.375 169.812C21.7812 169.812 22.7656 169.234 23.3281 168.078C23.9219 166.922 24.2188 165.422 24.2188 163.578V148.719H40.8125V164.328C40.8125 171.578 38.9844 176.844 35.3281 180.125C31.6719 183.375 26.5625 185 20 185Z" fill="url(#paint16_linear_113_96)" />
            <path d="M66.0312 185C59.4375 185 54.3906 183.016 50.8906 179.047C47.4219 175.047 45.6875 169.281 45.6875 161.75V122.562C45.6875 115.5 47.4062 110.141 50.8438 106.484C54.3125 102.828 59.375 101 66.0312 101C72.6875 101 77.7344 102.828 81.1719 106.484C84.6406 110.141 86.375 115.5 86.375 122.562V161.75C86.375 169.281 84.625 175.047 81.125 179.047C77.6562 183.016 72.625 185 66.0312 185ZM66.1719 169.812C68.6719 169.812 69.9219 167.391 69.9219 162.547V122.281C69.9219 118.219 68.7031 116.188 66.2656 116.188C63.5156 116.188 62.1406 118.266 62.1406 122.422V162.641C62.1406 165.203 62.4531 167.047 63.0781 168.172C63.7031 169.266 64.7344 169.812 66.1719 169.812Z" fill="url(#paint17_linear_113_96)" />
            <path d="M93.0312 184.25V101.75H110L117.594 141.266V101.75H133.531V184.25H117.406L109.156 143V184.25H93.0312Z" fill="url(#paint18_linear_113_96)" />
            <path d="M160.062 185C154.094 185 149.281 183.141 145.625 179.422C142 175.703 140.188 170.5 140.188 163.812V125.75C140.188 117.75 141.766 111.625 144.922 107.375C148.109 103.125 153.281 101 160.438 101C164.344 101 167.812 101.719 170.844 103.156C173.906 104.594 176.312 106.75 178.062 109.625C179.812 112.469 180.688 116.062 180.688 120.406V134.656H164.281V122.469C164.281 120 163.969 118.344 163.344 117.5C162.719 116.625 161.75 116.188 160.438 116.188C158.906 116.188 157.875 116.75 157.344 117.875C156.812 118.969 156.547 120.438 156.547 122.281V163.578C156.547 165.859 156.875 167.469 157.531 168.406C158.219 169.344 159.188 169.812 160.438 169.812C161.844 169.812 162.828 169.234 163.391 168.078C163.984 166.922 164.281 165.422 164.281 163.578V148.719H180.875V164.328C180.875 171.578 179.047 176.844 175.391 180.125C171.734 183.375 166.625 185 160.062 185Z" fill="url(#paint19_linear_113_96)" />
            <path d="M186.406 184.25V101.75H219.406V117.688H203.188V133.766H218.75V149.281H203.188V168.172H220.484V184.25H186.406Z" fill="url(#paint20_linear_113_96)" />
            <path d="M225.969 184.25V101.75H247.766C252.328 101.75 255.938 102.766 258.594 104.797C261.281 106.828 263.203 109.719 264.359 113.469C265.516 117.219 266.094 121.688 266.094 126.875C266.094 131.875 265.609 136.234 264.641 139.953C263.672 143.641 261.938 146.5 259.438 148.531C256.969 150.562 253.453 151.578 248.891 151.578H242.188V184.25H225.969ZM242.188 136.766H243.125C246.188 136.766 248.062 135.906 248.75 134.188C249.438 132.469 249.781 129.938 249.781 126.594C249.781 123.469 249.438 121.062 248.75 119.375C248.094 117.656 246.516 116.797 244.016 116.797H242.188V136.766Z" fill="url(#paint21_linear_113_96)" />
            <path d="M278.422 184.25V117.594H268.625V101.75H304.719V117.594H294.922V184.25H278.422Z" fill="url(#paint22_linear_113_96)" />
            <path d="M328.906 185C321.375 185 315.938 183.125 312.594 179.375C309.281 175.625 307.625 169.656 307.625 161.469V153.406H323.938V163.719C323.938 165.625 324.219 167.125 324.781 168.219C325.375 169.281 326.391 169.812 327.828 169.812C329.328 169.812 330.359 169.375 330.922 168.5C331.516 167.625 331.812 166.188 331.812 164.188C331.812 161.656 331.562 159.547 331.062 157.859C330.562 156.141 329.688 154.516 328.438 152.984C327.219 151.422 325.516 149.609 323.328 147.547L315.922 140.516C310.391 135.297 307.625 129.328 307.625 122.609C307.625 115.578 309.25 110.219 312.5 106.531C315.781 102.844 320.516 101 326.703 101C334.266 101 339.625 103.016 342.781 107.047C345.969 111.078 347.562 117.203 347.562 125.422H330.781V119.75C330.781 118.625 330.453 117.75 329.797 117.125C329.172 116.5 328.312 116.188 327.219 116.188C325.906 116.188 324.938 116.562 324.312 117.312C323.719 118.031 323.422 118.969 323.422 120.125C323.422 121.281 323.734 122.531 324.359 123.875C324.984 125.219 326.219 126.766 328.062 128.516L337.578 137.656C339.484 139.469 341.234 141.391 342.828 143.422C344.422 145.422 345.703 147.766 346.672 150.453C347.641 153.109 348.125 156.359 348.125 160.203C348.125 167.953 346.688 174.031 343.812 178.438C340.969 182.812 336 185 328.906 185Z" fill="url(#paint23_linear_113_96)" />
            <path d="M390.594 185.234C385.469 185.234 381.438 183.25 378.5 179.281C375.594 175.312 374.141 169.844 374.141 162.875C374.141 157.906 374.578 153.781 375.453 150.5C376.359 147.219 377.609 144.484 379.203 142.297C380.797 140.109 382.625 138.203 384.688 136.578L384.312 135.641C383 132.391 381.844 129.297 380.844 126.359C379.875 123.391 379.391 120.344 379.391 117.219C379.391 114.062 380.062 111.266 381.406 108.828C382.75 106.391 384.609 104.484 386.984 103.109C389.391 101.703 392.141 101 395.234 101C398.297 101 401.078 101.672 403.578 103.016C406.109 104.328 408.125 106.312 409.625 108.969C411.125 111.625 411.875 114.984 411.875 119.047C411.875 123.453 411.047 127.141 409.391 130.109C407.766 133.078 405.312 135.938 402.031 138.688L406.531 150.031C408.375 145.375 411.031 141.766 414.5 139.203L421.016 149.375C417.391 152.281 414.359 157.016 411.922 163.578L420.172 184.25H404.234L402.406 179.797C401.188 181.297 399.547 182.578 397.484 183.641C395.453 184.703 393.156 185.234 390.594 185.234ZM394.109 115.766C394.109 116.578 394.312 117.781 394.719 119.375C395.125 120.938 396.078 123.656 397.578 127.531C398.172 126.906 398.703 125.922 399.172 124.578C399.641 123.234 399.875 121.391 399.875 119.047C399.875 116.641 399.578 114.953 398.984 113.984C398.422 113.016 397.672 112.531 396.734 112.531C395.828 112.531 395.156 112.875 394.719 113.562C394.312 114.219 394.109 114.953 394.109 115.766ZM388.484 164.891C388.484 170.453 390.062 173.234 393.219 173.234C394.5 173.234 395.531 172.766 396.312 171.828C397.094 170.859 397.625 169.859 397.906 168.828L390.031 149.656C389 151.844 388.484 155.672 388.484 161.141V164.891Z" fill="url(#paint24_linear_113_96)" />
            <path d="M467.844 185C461.875 185 457.062 183.141 453.406 179.422C449.781 175.703 447.969 170.5 447.969 163.812V125.75C447.969 117.75 449.547 111.625 452.703 107.375C455.891 103.125 461.062 101 468.219 101C472.125 101 475.594 101.719 478.625 103.156C481.688 104.594 484.094 106.75 485.844 109.625C487.594 112.469 488.469 116.062 488.469 120.406V134.656H472.062V122.469C472.062 120 471.75 118.344 471.125 117.5C470.5 116.625 469.531 116.188 468.219 116.188C466.688 116.188 465.656 116.75 465.125 117.875C464.594 118.969 464.328 120.438 464.328 122.281V163.578C464.328 165.859 464.656 167.469 465.312 168.406C466 169.344 466.969 169.812 468.219 169.812C469.625 169.812 470.609 169.234 471.172 168.078C471.766 166.922 472.062 165.422 472.062 163.578V148.719H488.656V164.328C488.656 171.578 486.828 176.844 483.172 180.125C479.516 183.375 474.406 185 467.844 185Z" fill="url(#paint25_linear_113_96)" />
            <path d="M513.875 185C507.281 185 502.234 183.016 498.734 179.047C495.266 175.047 493.531 169.281 493.531 161.75V122.562C493.531 115.5 495.25 110.141 498.688 106.484C502.156 102.828 507.219 101 513.875 101C520.531 101 525.578 102.828 529.016 106.484C532.484 110.141 534.219 115.5 534.219 122.562V161.75C534.219 169.281 532.469 175.047 528.969 179.047C525.5 183.016 520.469 185 513.875 185ZM514.016 169.812C516.516 169.812 517.766 167.391 517.766 162.547V122.281C517.766 118.219 516.547 116.188 514.109 116.188C511.359 116.188 509.984 118.266 509.984 122.422V162.641C509.984 165.203 510.297 167.047 510.922 168.172C511.547 169.266 512.578 169.812 514.016 169.812Z" fill="url(#paint26_linear_113_96)" />
            <path d="M540.875 184.25V101.75H566L572.938 152.047L579.828 101.75H605.188V184.25H590.094V124.812L580.625 184.25H565.812L555.781 124.812V184.25H540.875Z" fill="url(#paint27_linear_113_96)" />
            <path d="M612.5 184.25V101.75H637.625L644.562 152.047L651.453 101.75H676.812V184.25H661.719V124.812L652.25 184.25H637.438L627.406 124.812V184.25H612.5Z" fill="url(#paint28_linear_113_96)" />
            <path d="M703.203 185C696.328 185 691.266 183.078 688.016 179.234C684.766 175.359 683.141 169.672 683.141 162.172V101.75H699.172V161.516C699.172 162.891 699.25 164.219 699.406 165.5C699.562 166.75 699.922 167.781 700.484 168.594C701.047 169.406 701.953 169.812 703.203 169.812C704.484 169.812 705.406 169.422 705.969 168.641C706.531 167.828 706.875 166.781 707 165.5C707.156 164.219 707.234 162.891 707.234 161.516V101.75H723.266V162.172C723.266 169.672 721.641 175.359 718.391 179.234C715.141 183.078 710.078 185 703.203 185Z" fill="url(#paint29_linear_113_96)" />
            <path d="M729.594 184.25V101.75H746.562L754.156 141.266V101.75H770.094V184.25H753.969L745.719 143V184.25H729.594Z" fill="url(#paint30_linear_113_96)" />
            <path d="M776.656 184.25V101.75H792.594V184.25H776.656Z" fill="url(#paint31_linear_113_96)" />
            <path d="M818.375 185C812.406 185 807.594 183.141 803.938 179.422C800.312 175.703 798.5 170.5 798.5 163.812V125.75C798.5 117.75 800.078 111.625 803.234 107.375C806.422 103.125 811.594 101 818.75 101C822.656 101 826.125 101.719 829.156 103.156C832.219 104.594 834.625 106.75 836.375 109.625C838.125 112.469 839 116.062 839 120.406V134.656H822.594V122.469C822.594 120 822.281 118.344 821.656 117.5C821.031 116.625 820.062 116.188 818.75 116.188C817.219 116.188 816.188 116.75 815.656 117.875C815.125 118.969 814.859 120.438 814.859 122.281V163.578C814.859 165.859 815.188 167.469 815.844 168.406C816.531 169.344 817.5 169.812 818.75 169.812C820.156 169.812 821.141 169.234 821.703 168.078C822.297 166.922 822.594 165.422 822.594 163.578V148.719H839.188V164.328C839.188 171.578 837.359 176.844 833.703 180.125C830.047 183.375 824.938 185 818.375 185Z" fill="url(#paint32_linear_113_96)" />
            <path d="M842.469 184.25L850.438 101.75H878.422L886.25 184.25H870.641L869.469 170.938H859.531L858.5 184.25H842.469ZM860.703 157.766H868.203L864.594 115.812H863.844L860.703 157.766Z" fill="url(#paint33_linear_113_96)" />
            <path d="M897.172 184.25V117.594H887.375V101.75H923.469V117.594H913.672V184.25H897.172Z" fill="url(#paint34_linear_113_96)" />
            <path d="M927.312 184.25V101.75H943.25V184.25H927.312Z" fill="url(#paint35_linear_113_96)" />
            <path d="M969.5 185C962.906 185 957.859 183.016 954.359 179.047C950.891 175.047 949.156 169.281 949.156 161.75V122.562C949.156 115.5 950.875 110.141 954.312 106.484C957.781 102.828 962.844 101 969.5 101C976.156 101 981.203 102.828 984.641 106.484C988.109 110.141 989.844 115.5 989.844 122.562V161.75C989.844 169.281 988.094 175.047 984.594 179.047C981.125 183.016 976.094 185 969.5 185ZM969.641 169.812C972.141 169.812 973.391 167.391 973.391 162.547V122.281C973.391 118.219 972.172 116.188 969.734 116.188C966.984 116.188 965.609 118.266 965.609 122.422V162.641C965.609 165.203 965.922 167.047 966.547 168.172C967.172 169.266 968.203 169.812 969.641 169.812Z" fill="url(#paint36_linear_113_96)" />
            <path d="M996.5 184.25V101.75H1013.47L1021.06 141.266V101.75H1037V184.25H1020.88L1012.62 143V184.25H996.5Z" fill="url(#paint37_linear_113_96)" />
            <path d="M1063.91 185C1056.38 185 1050.94 183.125 1047.59 179.375C1044.28 175.625 1042.62 169.656 1042.62 161.469V153.406H1058.94V163.719C1058.94 165.625 1059.22 167.125 1059.78 168.219C1060.38 169.281 1061.39 169.812 1062.83 169.812C1064.33 169.812 1065.36 169.375 1065.92 168.5C1066.52 167.625 1066.81 166.188 1066.81 164.188C1066.81 161.656 1066.56 159.547 1066.06 157.859C1065.56 156.141 1064.69 154.516 1063.44 152.984C1062.22 151.422 1060.52 149.609 1058.33 147.547L1050.92 140.516C1045.39 135.297 1042.62 129.328 1042.62 122.609C1042.62 115.578 1044.25 110.219 1047.5 106.531C1050.78 102.844 1055.52 101 1061.7 101C1069.27 101 1074.62 103.016 1077.78 107.047C1080.97 111.078 1082.56 117.203 1082.56 125.422H1065.78V119.75C1065.78 118.625 1065.45 117.75 1064.8 117.125C1064.17 116.5 1063.31 116.188 1062.22 116.188C1060.91 116.188 1059.94 116.562 1059.31 117.312C1058.72 118.031 1058.42 118.969 1058.42 120.125C1058.42 121.281 1058.73 122.531 1059.36 123.875C1059.98 125.219 1061.22 126.766 1063.06 128.516L1072.58 137.656C1074.48 139.469 1076.23 141.391 1077.83 143.422C1079.42 145.422 1080.7 147.766 1081.67 150.453C1082.64 153.109 1083.12 156.359 1083.12 160.203C1083.12 167.953 1081.69 174.031 1078.81 178.438C1075.97 182.812 1071 185 1063.91 185Z" fill="url(#paint38_linear_113_96)" />
            <defs>
                <linearGradient id="paint0_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint1_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint2_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint3_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint4_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint5_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint6_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint7_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint8_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint9_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint10_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint11_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint12_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint13_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint14_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint15_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint16_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint17_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint18_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint19_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint20_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint21_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint22_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint23_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint24_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint25_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint26_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint27_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint28_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint29_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint30_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint31_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint32_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint33_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint34_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint35_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint36_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint37_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
                <linearGradient id="paint38_linear_113_96" x1="1104" y1="10.7498" x2="-2.69326e-05" y2="201.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F15625" />
                    <stop offset="0.28" stopColor="#5093AB" />
                    <stop offset="0.52" stopColor="#9DCB3B" />
                    <stop offset="0.735" stopColor="#E9E748" />
                    <stop offset="1" stopColor="#393939" />
                </linearGradient>
            </defs>
        </svg>

    )
}
export default HomeGradientText;