import BannerVideo from "../components/banner-video";
import MobileAboutUs from "../components/mobile-about-us";
import MobileClients from "../components/mobile-clients";
import MobileFooter from "../components/mobile-footer";
import MobileHomeBanner from "../components/mobile-home-banner";
import MobileInsights from "../components/mobile-insights";
import MobileQuickLinks from "../components/mobile-quick-link";
import MobileServices from "../components/mobile-services";

function MobileHome() {
  return (
    <>
      <div className="relative hidden max-xs:block">
        <div className="w-full h-screen fixed top-0 left-0 z-[1]">
          <BannerVideo overlay="video-overlay-mobile" />
        </div>
        <div className="w-full h-auto absolute top-0 left-0 z-[2]">
          <MobileHomeBanner />
          <MobileAboutUs />
          <MobileServices />
          <MobileInsights />
          <MobileClients />
          <MobileQuickLinks />
          <MobileFooter />
        </div>
      </div>
    </>
  );
}
export default MobileHome;
