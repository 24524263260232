import DesktopContactUs from "./desktop-contact-us";
import MobileContactUs from "./mobile-contact-us";

function ContactUs() {
  return (
    <>
      <div className="block max-xs:hidden">
        <DesktopContactUs />
      </div>
      <div className="hidden max-xs:block">
        <MobileContactUs />
      </div>
    </>
  );
}
export default ContactUs;
