import { Outlet } from "react-router";
import MobileFooter from "../components/mobile-footer";
import ProjectGradientText from "../components/project-gradient-text";
import VideoProductionProjects from "../components/video-production-projects";
import VisualBrandingProjects from "../components/visual-branding-projects";
import { useEffect } from "react";

function MobileProjects() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div className="w-full h-auto  bg-7d-gray-20 z-[5]">
        <div className="w-full h-full pt-[10vh] px-8 overflow-auto">
          <div className="w-full h-auto mt-[4%] mb-[5%]">
            <ProjectGradientText id="mobileProjectHeading" />
          </div>
          <VisualBrandingProjects type="mobile" />
          <VideoProductionProjects type="mobile" />
          <MobileFooter />
        </div>
      </div>
      <Outlet />
    </>
  );
}
export default MobileProjects;
