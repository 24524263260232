import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Explore from "./pages/explore";
import Layout from "./pages/layout";
import ContactUs from "./pages/contact-us";
import Projects from "./pages/projects";
import VisualBranding from "./pages/visual-branding";
import VideoProduction from "./pages/video-production";
import VideoProductionDetail from "./pages/video-production-detail";
import VisualBrandingDetail from "./pages/visual-branding-detail";
import NotFound from "./pages/not-found";
import { AnimatePresence } from "framer-motion";
import MobileHome from "./pages/mobile-home";
import { useEffect } from "react";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      performance.getEntriesByType("navigation")[0].type === "reload" &&
      window.location.pathname !== "/"
    ) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MobileHome />}></Route>
          <Route path="/explore" element={<Explore />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/projects" element={<Projects />}>
            <Route path="visual-branding" element={<VisualBranding />}>
              <Route path=":slug" element={<VisualBrandingDetail />}></Route>
            </Route>
            <Route path="video-production" element={<VideoProduction />}>
              <Route path=":slug" element={<VideoProductionDetail />}></Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  );
}

export default App;
