import { useState } from "react";
import { ServiceAccordion } from "../services-accordion";
import { motion } from "framer-motion";

function MobileServices() {
  const [expanded, setExpanded] = useState(null);
  const accordionContent = [
    {
      id: "visualbranding",
      title: "Visual Branding",
      imgsrc: "our-services-1.webp",
      color: "group-hover:text-[#f15625]",
      activeColor: "#f15625",
      contenttitle:
        "Creating a memorable brand identity and story that connects with your target audience with lasting impressions and ensuring your brand stands out.",
      contentTags: "Digital design, Print design, UI/UX",
    },
    {
      id: "videoproduction",
      title: "Video Production",
      imgsrc: "our-services-2.webp",
      color: "group-hover:text-[#9dcb3b]",
      activeColor: "#9dcb3b",
      contenttitle:
        "In this digital world, where videos are king, our complete video production services will help you create awesome content to reach your goals.",
      contentTags:
        "Ad films, Short films, Documentaries, Commercial videos, B-roll",
    },
    {
      id: "events",
      title: "Events",
      imgsrc: "our-services-3.webp",
      color: "group-hover:text-[#5093AB]",
      activeColor: "#5093AB",
      contenttitle:
        "Strengthen your corporate events with our complete services, including customized planning, venue management, creative design, entertainment, food, and more to ensure unforgettable experiences that benefits your brand and objectives.",
      contentTags:
        "Conferences and Seminars, Product launches, Board meetings, Awards ceremonies, Networking events, etc.",
    },
    {
      id: "campaigns",
      title: "Campaigns",
      imgsrc: "our-services-4.webp",
      color: "group-hover:text-[#E9E748]",
      activeColor: "#E9E748",
      contenttitle:
        "Open up your brand's potential through our complete campaign services, resulting in impactful marketing strategies and amazing results.",
      contentTags: "Social Media Campaigns, Event Campaigns, etc.",
    },
  ];
  const cardVariants = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 1.5,
      },
    },
  };
  return (
    <div className="w-full h-auto scroll-mt-[10vh]" id="services">
      <div className="w-full h-auto px-8 py-20 bg-7d-gray-39 ">
        <motion.div
          className="w-full h-auto mb-10"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
        >
          <div className="w-full h-full overflow-hidden">
            <motion.div className="w-full" variants={cardVariants}>
              <p className="text-[2rem] font-blinker font-light text-7d-white">
                Elevating your online presence & driving results with our
                comprehensive digital marketing service
              </p>
            </motion.div>
          </div>
        </motion.div>
        <div className="w-full h-auto">
          <ServiceAccordion
            accordionContent={accordionContent}
            expanded={expanded}
            setExpanded={setExpanded}
            type="mobile"
          />
        </div>
      </div>
    </div>
  );
}
export default MobileServices;
