import clientsData from "../../json/clients.json";
import { motion } from "framer-motion";

function Clients() {
  
  const item = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,

      transition: {
        duration: 1,
      },
    },
  };
  return (
    <div className="w-full h-auto my-14 border-b border-7d-gray-70">
      <div className="w-full h-auto flex justify-between items-start mb-10">
        <div className="w-1/2 h-auto">
          <div className="w-full h-auto">
            <p className="primary-heading">Clientele</p>
          </div>
        </div>
        <div className="w-1/2 h-auto">
          <motion.div className="w-full h-auto">
            <div className="w-full h-auto grid grid-cols-3 gap-10">
              {clientsData.map((client, i) => (
                <motion.div
                  className="w-full h-full overflow-hidden"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <motion.div
                    key={i}
                    className="w-full h-auto filter saturate-0 hover:saturate-100 transition duration-300"
                    variants={item}
                  >
                    <div className="w-[16.7rem] max-md:w-[12rem] max-xl:w-[12rem] h-auto mx-auto">
                      <img
                        src={require(`../../images/clients/${client.src}`)}
                        alt={client.name}
                      />
                    </div>
                  </motion.div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
export default Clients;
