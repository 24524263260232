import { useNavigate } from "react-router";

function MobileQuickLinks() {
  const navigate = useNavigate();

  const quickLinks = [
    { lable: "Projects", link: "projects" },
    { lable: "Contact Us", link: "contact-us" },
  ];
  return (
    <div className="w-full h-auto">
      <ul className="w-full h-auto">
        {quickLinks.map((item, i) => (
          <li
            className="w-full h-auto px-8 pb-2 border-y-[0.5px] border-7d-border-gray first:border-b-0"
            onClick={() => navigate(`/${item.link}`)}
          >
            <div className="w-full h-auto flex justify-between items-center">
              <p className="text-[3.2rem] font-blinker text-7d-white">
                {item.lable}
              </p>
              <div className="w-[1.7rem] h-auto">
                <img
                  src={require("../../images/quicklinks-arrow.png")}
                  alt="Quick Links Arrow"
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default MobileQuickLinks;
