import { motion } from "framer-motion";

function ProjectsBanner({ translateY }) {
  return (
    <div className="w-full h-auto pb-24 mb-24 border-b border-7d-gray-70">
      <div className="w-full h-[35rem] max-xs:h-auto overflow-hidden flex justify-center items-center ">
        <motion.img
          className="w-full h-auto"
          src={require("../../images/projects-banner.webp")}
          alt="About Us Banner"
          style={{ translateY: translateY }}
        />
      </div>
    </div>
  );
}
export default ProjectsBanner;
