import { useState } from "react";
import { ServiceAccordion } from "../services-accordion";
import { motion, AnimatePresence } from "framer-motion";

function Services() {
  const [expanded, setExpanded] = useState(null);
  const accordionContent = [
    {
      id: "visualbranding",
      title: "Visual Branding",
      imgsrc: "our-services-1.webp",
      color: "group-hover:text-[#f15625]",
      activeColor: "#f15625",
      contenttitle:
        "Creating a memorable brand identity and story that connects with your target audience with lasting impressions and ensuring your brand stands out.",
      contentTags: "Digital design, Print design, UI/UX",
    },
    {
      id: "videoproduction",
      title: "Video Production",
      imgsrc: "our-services-2.webp",
      color: "group-hover:text-[#9dcb3b]",
      activeColor: "#9dcb3b",
      contenttitle:
        "In this digital world, where videos are king, our complete video production services will help you create awesome content to reach your goals.",
      contentTags:
        "Ad films, Short films, Documentaries, Commercial videos, B-roll",
    },
    {
      id: "events",
      title: "Events",
      imgsrc: "our-services-3.webp",
      color: "group-hover:text-[#5093AB]",
      activeColor: "#5093AB",
      contenttitle:
        "Strengthen your corporate events with our complete services, including customized planning, venue management, creative design, entertainment, food, and more to ensure unforgettable experiences that benefits your brand and objectives.",
      contentTags:
        "Conferences and Seminars, Product launches, Board meetings, Awards ceremonies, Networking events, etc.",
    },
    {
      id: "campaigns",
      title: "Campaigns",
      imgsrc: "our-services-4.webp",
      color: "group-hover:text-[#E9E748]",
      activeColor: "#E9E748",
      contenttitle:
        "Open up your brand's potential through our complete campaign services, resulting in impactful marketing strategies and amazing results.",
      contentTags: "Social Media Campaigns, Event Campaigns, etc.",
    },
  ];
  const cardVariants = {
    offscreen: {
      y: 150,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };
  return (
    <div className="w-full h-auto my-14 pb-16 border-b border-7d-gray-70">
      <div className="w-full h-auto flex justify-between items-start mb-10">
        <div className="w-1/2 h-auto">
          <div className="w-full h-auto">
            <p className="primary-heading">Our Services</p>
          </div>
        </div>
        <motion.div
          className="w-1/2 h-auto"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
        >
          <div className="w-full h-auto">
            <div className="w-full h-full overflow-hidden">
              <motion.div className="w-full" variants={cardVariants}>
                <p className="primary-heading mb-12">
                  We improve how people see you online and reach your goals with
                  our complete services
                </p>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between gap-10">
          <div className="w-1/2 h-auto">
            {accordionContent.map((item, i) => (
              <div key={i} className="w-full h-auto">
                <AnimatePresence initial={false}>
                  {i === expanded && (
                    <motion.div
                      key="contentimg"
                      initial="collapsed"
                      animate="open"
                      exit="open"
                      variants={{
                        open: { y: 0, opacity: 1 },
                        collapsed: { y: 200, opacity: 0 },
                      }}
                    >
                      <img
                        src={require(`../../images/${item.imgsrc}`)}
                        alt=""
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
          <div className="w-1/2 h-auto">
            <ServiceAccordion
              accordionContent={accordionContent}
              expanded={expanded}
              setExpanded={setExpanded}
              type="desktop"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Services;
